var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.costoFlag)?_c('v-card',[_c('h1',[_vm._v(" OPCIÓN COSTOS "+_vm._s(_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)+" ")]),_c('v-form',{ref:"frmDatosOpcion"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"date","error-messages":_vm.$store.state.pricing.fecha_inicio,"readonly":"","label":"Fecha de Registro de Cotización"},model:{value:(_vm.$store.state.pricing.datosPrincipales.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.datosPrincipales, "fecha_inicio", $$v)},expression:"$store.state.pricing.datosPrincipales.fecha_inicio"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"date","name":("Fecha Validez de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"label":("Fecha Validez de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"error-messages":_vm.$store.state.pricing.errfecha_fin,"rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return _vm.isDateValid(v) || 'La fecha debe ser mayor que hoy.'; } ]},model:{value:(
            _vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.page - 1]
              .date_end
          ),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.page - 1]
              , "date_end", $$v)},expression:"\n            $store.state.pricing.opcionCostos[$store.state.pricing.page - 1]\n              .date_end\n          "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"number","name":("Tiempo en tránsito de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"label":("Tiempo en tránsito de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"error-messages":_vm.$store.state.pricing.errtiempo_transito,"rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return v > 0 || 'El valor tiene que ser positivo.'; } ]},model:{value:(
            _vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.page - 1]
              .tiempo_transito
          ),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.page - 1]
              , "tiempo_transito", $$v)},expression:"\n            $store.state.pricing.opcionCostos[$store.state.pricing.page - 1]\n              .tiempo_transito\n          "}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.$store.state.pricing.page == _vm.$store.state.pricing.opcionCostos.length)?_c('v-btn',{staticClass:"mx-1 btn-ingresarcostos",attrs:{"color":"#3363A2","x-large":"","dark":""},on:{"click":function($event){return _vm.agregarNuevaOpcion()}}},[_vm._v(" AGREGAR NUEVA OPCIÓN "+_vm._s(_vm.$store.state.pricing.index + 1)+" ")]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('CostosComponent',{attrs:{"valores":_vm.opcionesSeleccionadas[
            _vm.$store.state.pricing.page - 1
          ].listCostos.filter(function (v) { return v.esopcionflag == 1; }),"actualizarCostosFlag":_vm.$store.state.pricing.actualizarCostosFlag,"amount":_vm.$store.state.pricing.datosPrincipales.amount,"index":_vm.page}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('VentasComponent',{attrs:{"valores":_vm.opcionesSeleccionadas[
            _vm.$store.state.pricing.page - 1
          ].listCostos.filter(function (v) { return v.esventaflag == 1; }),"amount":_vm.$store.state.pricing.datosPrincipales.amount}})],1),(_vm.getExisteImpuesto())?_c('v-col',{attrs:{"cols":"6","offset":"6"}},[(_vm.mostrarImpuestos())?_c('ImpuestosComponent',{attrs:{"amount":_vm.$store.state.pricing.datosPrincipales.amount,"index":_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta,"impuestos":_vm.$store.state.pricing.opcionCostos.filter(function (v) { return v.selected; })[
            _vm.$store.state.pricing.page - 1
          ].listImpuestos}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"derecha"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.$store.state.pricing.tab = 4}}},[_vm._v(" CONTINUAR ")])],1)],1),_c('v-col',{staticClass:"derecha",attrs:{"cols":"12"}},[[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.$store.state.pricing.opcionCostos.filter(function (v) { return v.selected; })
                .length,"circle":""},model:{value:(_vm.$store.state.pricing.page),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing, "page", $$v)},expression:"$store.state.pricing.page"}})],1)]],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }